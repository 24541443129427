import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setConfirm, setOpen } from "../redux/actions/initial";

function ConfirmationDialog() {
  const dispatch = useDispatch();
  let { fromIndex, toIndex, printed } = useSelector((state) => ({
    fromIndex: state.initial,
  }));
  let { open } = useSelector((state) => ({ open: state.initial.open }));
  // takes default value from the passed props
  const onClose = (val) => {
    dispatch(setOpen(false));
    dispatch(setConfirm(val));
  };
  const radioGroupRef = React.useRef(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogContent dividers sx={{ textAlign: "right", fontWeight: "Bold" }}>
        هل تريد اكمال الطباعة ؟
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          لا
        </Button>
        <Button onClick={handleOk}>نعم</Button>
      </DialogActions>
    </Dialog>
  );
}
export default React.memo(ConfirmationDialog);
