import { React, useState, useEffect, createContext } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import axiosInstance from "../helpers/AxiosInstance";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Header,
  Title,
  ShipmentsReportsTable,
  ButtonsContainer,
} from "../components/utilities";
import NewPrintBtn from "../components/utilities/NewPrintBtn";
import Next from "../assets/images/navigateNext.svg";
const Button = styled.button`
  border: 0px solid var(--primary-color);
  border-color: var(--primary-color);
  transition: border 0.5s;
  &:hover {
    border: 2px solid var(--primary-color);
  }
  cursor: pointer;
  min-width: 142px;
  height: 50px;
  background: ${(props) => (props.primary ? "var(--gradient)" : "transparent")};
  box-shadow: ${(props) => (props.primary ? "0px 3px 6px #0000005e" : "none")};
  border-radius: 5px;
  border: 0;
  opacity: 1;
  text-align: center;
  font: normal normal 900 15px Cairo;
  letter-spacing: 0px;
  color: ${(props) => (props.primary ? "white" : "black")};
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;
const NativationButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9pt;
  font-weight: bold;
  font-family: "Cairo";
  cursor: pointer;
  & .e-focus {
    border: none;
  }
`;
export const selectedContext = createContext();
export default function ShipmentsReport() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const [pageSize] = useState(500);
  const [lockedPage, setLockedPage] = useState(null);
  const [queryState, setQueryState] = useState({
    allShipments: true,
    delivered: false,
    returned: false,
    processing: false,
    paid: false,
    expected: false,
  });

  const { t, i18n } = useTranslation("reports");
  const instance = axiosInstance();
  const categories = [
    { statusID: 0, name: "allShipments" },
    { statusID: 1, name: "delivered" },
    { statusID: 2, name: "processing" },
    { statusID: 3, name: "returned" },
    { statusID: -1, name: "paid" },
    { statusID: -2, name: "expected" },
  ];
  const statusID = categories.find(
    (category) =>
      category.name ===
      Object.entries(queryState).find((entry) => entry[1] === true)[0]
  ).statusID;
  const getCurrent = () => {
    return currentPage === lockedPage;
  };
  const fetchShipments = async (statusID, currentPage) => {
    const data = await instance
      .post("/GetShipmentsPage", {
        mainStatusID: statusID,
        FromDate: "2020-04-20T13:23:30.141Z",
        ToDate: new Date().toISOString(),
        pageParam: {
          pageSize: pageSize,
          pageNumber: currentPage,
        },
      })
      .catch((error) => {
        if (error.response.status === 500) {
          navigate("/server-error");
        }
      });
    if (!data.data.shipments.length) {
      setLockedPage(currentPage);
    }
    return data.data.shipments.map((shipment) => {
      return {
        ...shipment,
        NetCOD: shipment.COD - shipment.ShipmentFees,
        runnerData: `
        ${shipment.RunnerName}  /
        ${shipment.RunnerMobile}
         `,
      };
    });
  };

  const { data } = useQuery(
    ["reports", statusID, currentPage],
    () => fetchShipments(statusID, currentPage),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 100000,
      staleTime: 100000,
    }
  );
  const buttons = {
    allShipments: false,
    delivered: false,
    returned: false,
    processing: false,
    paid: false,
    expected: false,
  };
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setCurrentPage(null);
    setCurrentPage(1);
    setLockedPage(null);
  }, [statusID]);
  useEffect(() => {
    if (searchParams.has("report")) {
      const status = searchParams.get("report");
      const statuses = [
        "processing",
        "returned",
        "paid",
        "expected",
        "allShipments",
        "delivered",
      ];
      if (statuses.includes(status)) {
        setQueryState({ ...buttons, [status]: true });
      }
    }
  }, [searchParams]);
  const changeUrl = (value) => {
    setSearchParams(`report=${value}`);
  };
  function changeActiveButton(e) {
    let status = e.target.dataset.status;
    changeUrl(status);
    setQueryState({ ...buttons, [status]: true });
  }
  return (
    <selectedContext.Provider value={{ selected, setSelected }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Header>
          <Title>{t("shipments_reports")}</Title>
        </Header>
        <Box sx={{ mb: 3, display: "flex", gap: 2 }}>
          {/* <PrintBtn print="shipments" data={selected} /> */}
          <NewPrintBtn print="shipments" data={selected} />
        </Box>
      </Box>

      <Box sx={{ marginTop: "10px", width: "100%" }}>
        <ButtonsContainer>
          <Button
            primary={queryState.allShipments}
            data-status="allShipments"
            onClick={changeActiveButton}
          >
            {t("allShipments")}
          </Button>
          <Button
            primary={queryState.delivered}
            data-status="delivered"
            onClick={changeActiveButton}
          >
            {t("delivered")}
          </Button>
          <Button
            primary={queryState.returned}
            data-status="returned"
            onClick={changeActiveButton}
          >
            {t("returned")}
          </Button>
          <Button
            primary={queryState.processing}
            data-status="processing"
            onClick={changeActiveButton}
          >
            {t("processing")}
          </Button>
          <Button
            primary={queryState.paid}
            data-status="paid"
            onClick={changeActiveButton}
          >
            {t("received_payments")}
          </Button>
          <Button
            primary={queryState.expected}
            data-status="expected"
            onClick={changeActiveButton}
          >
            {t("expected_payments")}
          </Button>
        </ButtonsContainer>
      </Box>

      <Box sx={{ margin: "10px", width: "100%" }}>
        <ShipmentsReportsTable
          rows={data}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          lockedPage={lockedPage}
          getCurrent={getCurrent}
          currentStatus={searchParams.get("report")}
        />
        <Box
          sx={{
            display: "flex !important",
            paddingInline: 1,
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            border: "1px solid #e0e0e0",
          }}
        >
          <NativationButton
            onClick={() => setCurrentPage((old) => Math.max(old - 1, 1))}
            disabled={currentPage === 1}
          >
            <Box
              component={"img"}
              src={Next}
              sx={{
                width: "30px",
                height: "30px",
                transform: i18n.language !== "ar" && "rotate(180deg)",
              }}
            />
            <Box sx={{ display: "grid", placeItems: "center" }}>
              {t("export_data.previous_btn")}
            </Box>
          </NativationButton>
          <NativationButton
            disabled={getCurrent()}
            onClick={() => {
              setCurrentPage((old) => old + 1);
            }}
          >
            <Box sx={{ display: "grid", placeItems: "center" }}>
              {t("export_data.next_btn")}
            </Box>

            <Box
              component={"img"}
              src={Next}
              sx={{
                width: "30px",
                height: "30px",
                transform: i18n.language === "ar" && "rotate(180deg)",
              }}
            />
          </NativationButton>
        </Box>
      </Box>
    </selectedContext.Provider>
  );
}
