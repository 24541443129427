import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";
import styled from "styled-components";
import InputGroup from "../utilities/InputGroup";
import SelectInputGroup from "../utilities/SelectInputGroup";
import TextAreaInputGroup from "../utilities/TextAreaInputGroup";
import Button from "../styled/Button";

const Card = styled.div`
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 45%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #010e3f;
  opacity: 1;
`;
const FormContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Form = styled.div`
  width: 70%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ExitButton = styled.button`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  right: 0;
  border: 0;
  background: transparent
    linear-gradient(
      180deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 0px 10px 0px 0px;
  opacity: 1;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #ffffff;
`;
export default function MyModal(props) {
  const { t } = useTranslation("tickets");
  const { language } = useTranslation().i18n;

  const schema = yup
    .object({
      statement: yup.string().required("هذا الحقل مطلوب"),
      type: yup.string().required("هذا الحقل مطلوب"),
      awb: yup.string().required("هذا الحقل مطلوب"),
      content: yup.string().required("هذا الحقل مطلوب"),
    })
    .required();

  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      statement: "",
      type: "",
      awb: "",
      content: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const submitForm = (data) => {};

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <ExitButton onClick={() => props.setOpen(false)}>X</ExitButton>
        <Title>
          {t("new_ticket", {
            ns: "tickets",
          })}
        </Title>
        <FormProvider {...methods}>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(submitForm)}>
            <div dir={language === "ar" ? "rtl" : "ltr"}>
              <FormContainer>
                <Form>
                  <SelectInputGroup
                    label={t("statement", {
                      ns: "tickets",
                    })}
                    name="statement"
                    items={["statement1", "statement2"]}
                    placeholder={t("statement", {
                      ns: "tickets",
                    })}
                    error={errors.statement}
                  />
                  <SelectInputGroup
                    label={t("statement_type", {
                      ns: "tickets",
                    })}
                    name="type"
                    items={["statement1", "statement2"]}
                    placeholder={t("statement_type", {
                      ns: "tickets",
                    })}
                    error={errors.type}
                  />
                  <InputGroup
                    label={`${t("awb", {
                      ns: "tickets",
                    })}`}
                    input={{
                      placeholder: `${t("awb", {
                        ns: "tickets",
                      })}`,
                    }}
                    name="awb"
                    error={errors.awb}
                  />
                  <TextAreaInputGroup
                    label={`${t("content", {
                      ns: "tickets",
                    })}`}
                    placeholder={`${t("content", {
                      ns: "tickets",
                    })}`}
                    name="content"
                    error={errors.content}
                  />
                  <Button onClick={() => props.setOpen(false)}>
                    {t("submit", {
                      ns: "tickets",
                    })}
                  </Button>
                </Form>
              </FormContainer>
            </div>
          </form>
        </FormProvider>
      </Card>
    </Modal>
  );
}
