import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import printSelected from "../../helpers/labelPrint/index";
import { MenuItem, Select, FormControl, InputBase } from "@mui/material";
import ConfirmationDialog from "../ConfirmationDialog";
import LabelPreview from "../LabelPreview";
import { useQuery } from "react-query";
import axiosInstance from "../../helpers/AxiosInstance";
const StyledSelect = styled(InputBase)(() => ({
  " .MuiInputBase-input": {
    position: "relative",
    border: "1px solid var(--primary-color)",
    fontSize: 13,
    paddingBlock: "12px",
    paddingLeft: "10px",
    width: "117px",
    fontWeight: "bold",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #0000005E",
  },
  ".MuiSelect-iconOutlined": {
    color: "var(--primary-color)",
  },
}));
export default function NewPrintBtn({ data, print, setLoading, setError }) {
  const instance = axiosInstance();
  const [label, setLabel] = useState(null);
  const { data: labelData, isLoading } = useQuery(
    "getLabels",
    async () => {
      const response = await instance.get("GetPDFTemplates");
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  setLoading = setLoading ? setLoading : () => {};
  const { t } = useTranslation("uploadExcel");
  const [value, setValue] = React.useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const handleChange = (event) => {
    const showPreview = async () => {
      setOpenPreview(true);
      setLabel(
        labelData?.find((label) => label.templateID === event.target.value)
      );
    };
    if (data.length > 0 || data.toString().length > 0) {
      if (print === "shipments") {
        showPreview().then(() => {
          setValue("");
        });
      } else {
        showPreview().then(() => {
          setValue("");
        });
      }
    }
    // do the export action
  };
  return (
    <div>
      <ConfirmationDialog id="ringtone-menu" keepMounted />
      <FormControl>
        <Select
          input={<StyledSelect defaultValue={""} />}
          value={value}
          id="export"
          onChange={handleChange}
          displayEmpty
          disableunderline="true"
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" disabled>
            <em>{t("export_pdf")}</em>
          </MenuItem>
          {labelData?.map((label) => (
            <MenuItem value={label.templateID}>{label.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <LabelPreview
        data={data}
        label={label}
        open={openPreview}
        setOpen={setOpenPreview}
      />
    </div>
  );
}
