import styled from "styled-components";

const Button = styled.button`
  font-family: "cairo";
  width: 162px;
  cursor: pointer;
  min-width: 162px;
  height: 50px;
  background: var(--gradient);
  box-shadow: 0px 3px 6px #0000005e;
  border-radius: 5px;
  border: 0;
  opacity: 1;
  text-align: center;
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  display: grid;
  place-items: center;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
  &:hover {
    scale: 1.05;
  }
`;

export default Button;
