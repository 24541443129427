import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
const Style2 = {
  title: "Style 2 (A4)",
  css: function () {
    return `
            *{margin:0; padding:0;box-sizing: border-box;}
            :root{--container-height:1040px;--a4-height:calc(var(--container-height) / 2)}
            .a4-report{position: relative;width:100%;height:calc(var(--a4-height) - 4.7px);border:.1px solid;padding:5px;font-family:sans-serif;display:grid;gap:10px;margin-bottom:10px}
            .a4-report section{padding:5px 40px;border:1px solid;display: grid;gap:5px;}
            .normal-data{display:grid;gap:5px;}
            .line{display:flex;justify-content: space-between;align-items: center;}
            .company-website{text-align:center}
            .shipper-image{ width:120px; height:70px;}.shipper-image img {width:100%; height:100%}
            `;
  },
  size: "A4",
  render: function (data, Courier) {
    const {
      ToCityName,
      AWB,
      ClientName,
      ToAddress,
      ToConsigneeName,
      Telephone,
      SpecialInstuctions,
      COD,
      Ref,
      ToRef,
      Weight,
      Pcs,
      ToMobile,
      Pieces,
      Contents,
      ProductName,
      Data1,
      Data2,
      ToPhone,
    } = data;
    const { Logo1, EnglishName, WebSite, EnglishAddress } = Courier;

    const reportElement = document.createElement("div");
    reportElement.innerHTML = `
            <div class="a4-report">
                <section class="report-header">
                    <div class="line">
                        <div class="shipper-image"><img src="data:image/png;base64,${Logo1}"></div>
                        <div class="report-header-center">
                            <svg id="barcode-${AWB}"></svg>
                            <div class="company-website">
                                <a href="${WebSite}">${WebSite}</a>
                            </div>
                        </div>
                        <div class="qr-code-${AWB}" ></div>
                    </div>
                </section>
                <section class="report-shipper">
                    <div class="line">
                        <div>
                            Shipper : ${EnglishName}
                        </div>
        
                        <div class="">
                            ${ClientName}
                        </div>
                        <div class="qr-code"> 
                            Product: ${ProductName}
                        </div>
                    </div>
                    <div class="line">
                        Address: ${EnglishAddress}
                    </div>
                </section>
                <section class="report-consignee">
                    <div class="line">
                        <div>
                            City : ${ToCityName} 
                        </div>
        
                        <div class="">
                            Contact Person: 
                        </div>
                    </div>
                    <div class="normal-data">
                        <div class="consignee-address">
                            Address: ${ToAddress}  
                        </div>
                        <div class="consignee-name">
                            Consignee:  ${ToConsigneeName}
                        </div>
                    </div>
                    <div class="line">
                        <div class="consignee-phone">
                            Phone: ${ToMobile} ${ToPhone ? " / " + ToPhone : ""}
                        </div>
                        <div class="consignee-ref">
                            Ref: ${Ref ? Ref : ToRef}
                        </div>
                    </div>
                </section>
                <section class="report-shipment">
                    <div class="line">
                        <div>
                            COD : ${COD}
                        </div>
                        <div class="">
                            Weight : ${Weight}
                        </div>
                        <div > 
                            Pieces: ${Pcs ? Pcs : Pieces}
                        </div>
                    </div>
                    <div class="shipment-contents">
                        Contents : ${Contents}
                    </div>
                    <div class="shipment-instructions">
                        Instructions :   ${
                          SpecialInstuctions ? SpecialInstuctions : ""
                        }
                    </div>
                    <div>
                        Data : ${Data1 ? Data1 : ""}
                    </div>
                    <div>
                        Data : ${Data2 ? Data2 : ""}
                    </div>
                </section>
            </div>
            `;
    JsBarcode(reportElement.querySelector("#barcode-" + AWB), AWB, {
      height: 80,
    });
    reportElement.querySelector("#barcode-" + AWB).style.cssText =
      "transform:scale(.7)";
    QRCode.toCanvas(AWB, { width: 145, height: 145 }, function (err, canvas) {
      if (err) throw err;
      reportElement.querySelector(".qr-code-" + AWB).appendChild(canvas);
    });
    return reportElement;
  },
};
export default Style2;
