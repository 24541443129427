import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../helpers/AxiosInstance";
const initialState = {
  isLoggedIn: true,
  AuthedUser: null,

  companyData: false,
  courierData: null,
};
// ============================= Tool Kit ================================
export const handleCompanyCode = createAsyncThunk(
  "Auth/handleCompanyCode",
  async ({ code, setLoading, setStep }) => {
    const response = await axios.get(
      "https://ahmedaglan.github.io/ClientsJSON/ClientsMPS.json"
    );
    const data = response.data.ClientsList;
    const founded = await data.find(
      (client) => client.ClientID === parseInt(code.trim())
    );
    // send data by action.payload and trim it as founded in fulfilled processing
    if (founded) {
      setStep(2);
      setLoading(false);
      return founded;
    }
  }
);
// =======================================================================
export const checkCompanyData = createAsyncThunk(
  "Auth/checkCompanyData",
  async () => {
    let res = await axios.get("/meta/metadata.json");
    return res.data;
  }
);
// =============================================================================
export const handleUserLogin = createAsyncThunk(
  "auth/handleUserLogin",
  async ({ data, setLoading }) => {
    const instance = axiosInstance();
    const { username, password } = data;
    setLoading(true);
    const response = await instance.post("/Login", {
      username,
      password,
    });
    return response.data;
  }
);
// ==========================================================================
export const handleLogout = createAsyncThunk("Auth/handleLogout", async () => {
  const instance = axiosInstance();
  const response = await instance.post("/Logout");
  return response;
});
// ============================================================================
const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    //actions
    checkUser: (state) => {
      const AuthedUser = localStorage.getItem("AuthedUser");
      if (AuthedUser) {
        const authedUserData = JSON.parse(AuthedUser);
        state.AuthedUser = authedUserData;
      } else {
        state.isLoggedIn = false;
      }
    },
    logOut: (state) => {
      state.AuthedUser = null;
      state.isLoggedIn = false;
    },
    checkCompany: (state, action) => {
      const companyData = localStorage.getItem("CompanyData");
      if (companyData) {
        state.companyData = JSON.parse(companyData);
        action.payload.setStep(2);
      } else {
        action.payload.setStep(1);
      }
    },
  },
  extraReducers: {
    [handleUserLogin.pending]: (state) => {
      state.loading = true;
    },
    [handleUserLogin.fulfilled]: (state, action) => {
      state.loading = false;
      state.AuthedUser = action.payload;
      localStorage.setItem("AuthedUser", JSON.stringify(action.payload));
      state.isLoggedIn = true;
    },
    [handleUserLogin.rejected]: (state) => {
      state.loading = false;
    },
    [handleCompanyCode.pending]: (state) => {
      state.loading = true;
    },
    [handleCompanyCode.fulfilled]: (state, action) => {
      if (typeof action.payload == "undefined") {
        state.companyData = false;
      } else {
        state.loading = false;
        state.companyData = action.payload;
        localStorage.setItem("CompanyData", JSON.stringify(action.payload));
      }
    },
    [handleCompanyCode.rejected]: (state) => {
      state.loading = false;
    },
    [handleLogout.pending]: (state, action) => {
      state.loading = true;
    },
    [handleLogout.fulfilled]: (state, action) => {
      state.loading = false;
      state.AuthedUser = action.payload;
      localStorage.removeItem("AuthedUser");
      state.isLoggedIn = false;
    },
    [handleLogout.rejected]: (state, action) => {
      state.loading = false;
      localStorage.removeItem("AuthedUser");
    },
    [checkCompanyData.pending]: (state, action) => {
      state.loading = true;
    },
    [checkCompanyData.fulfilled]: (state, action) => {
      if (typeof action.payload === "string") {
        const companyData = localStorage.getItem("CompanyData");
        if (companyData) {
          let companyMetaData = JSON.parse(companyData);
          delete companyMetaData.defined;
          state.companyData = companyMetaData;
        } else {
          if (action.payload.setStep) {
            action.payload.setStep(1);
          }
        }
      } else {
        const metaData = { ...action.payload, defined: true };
        localStorage.removeItem("CompanyData");
        localStorage.setItem("CompanyData", JSON.stringify(metaData));
        state.companyData = metaData;
      }
    },
    [checkCompanyData.rejected]: (state, action) => {
      const companyData = localStorage.getItem("CompanyData");
      if (companyData) {
        const companyMetaData = JSON.parse(companyData);
        delete companyMetaData.defined;
        state.companyData = companyMetaData;
      }
    },
  },
});

export const { checkCompany, checkUser, logOut } = authSlice.actions;
export default authSlice.reducer;
