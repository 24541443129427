import React from "react";

export default function ArabicFlagSVG() {
  return (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
      >
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,371.000000 
	C513.000000,418.303345 513.000000,465.606689 513.000000,512.955017 
	C342.333344,512.955017 171.666672,512.955017 1.000000,512.955017 
	C1.000000,500.922150 1.000000,488.929718 1.350629,476.581665 
	C3.818024,483.911469 12.826169,490.690430 20.400999,489.883484 
	C33.031631,488.537964 39.953197,479.699066 39.981014,468.467377 
	C40.057259,437.680511 40.042355,406.893372 40.526192,376.060547 
	C193.434158,376.010406 345.879456,375.986755 498.324738,376.077484 
	C503.989075,376.080841 509.022614,375.350006 513.000000,371.000000 
z"
        />
        <path
          fill="#474756"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,370.531342 
	C509.022614,375.350006 503.989075,376.080841 498.324738,376.077484 
	C345.879456,375.986755 193.434158,376.010406 40.352699,375.671509 
	C39.483040,374.040497 39.045967,372.752808 39.045723,371.465027 
	C39.039211,337.247559 39.077721,303.030090 39.550396,268.962646 
	C41.818069,269.183746 43.643913,269.316864 45.469765,269.316986 
	C199.328369,269.326538 353.186951,269.327026 507.045563,269.312317 
	C509.030365,269.312134 511.015198,269.108643 513.000000,269.000000 
	C513.000000,302.687561 513.000000,336.375122 513.000000,370.531342 
z"
        />
        <path
          fill="#FF4B55"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,62.000000 
	C513.000000,95.354225 513.000000,128.708450 512.537476,162.615341 
	C509.743713,163.181839 507.412445,163.207596 505.081146,163.207779 
	C349.868652,163.220673 194.656143,163.231522 39.330055,162.767120 
	C39.190479,128.591156 39.152988,94.890587 39.178497,61.190075 
	C39.179573,59.768040 39.766220,58.346447 40.542755,56.956009 
	C193.678741,56.991585 346.352051,56.995804 499.025360,57.000050 
	C500.357300,57.000084 501.861908,56.595650 502.988861,57.076412 
	C506.404419,58.533566 509.673706,60.333565 513.000000,62.000000 
z"
        />
        <path
          fill="#F5F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M39.443657,163.242538 
	C194.656143,163.231522 349.868652,163.220673 505.081146,163.207779 
	C507.412445,163.207596 509.743713,163.181839 512.537476,163.084000 
	C513.000000,198.020889 513.000000,233.041794 513.000000,268.531372 
	C511.015198,269.108643 509.030365,269.312134 507.045563,269.312317 
	C353.186951,269.327026 199.328369,269.326538 45.469765,269.316986 
	C43.643913,269.316864 41.818069,269.183746 39.574600,268.506897 
	C39.159321,241.647415 39.139256,215.393692 39.176750,189.140060 
	C39.189079,180.507446 39.350441,171.875046 39.443657,163.242538 
M288.623077,236.212799 
	C290.969086,238.939270 293.315094,241.665756 295.661102,244.392227 
	C296.138245,244.032410 296.615417,243.672577 297.092560,243.312759 
	C297.092560,228.563797 297.242401,213.812286 296.990112,199.067642 
	C296.924805,195.251694 293.302063,193.306976 289.245087,194.235779 
	C287.012115,194.747009 284.854736,195.588394 282.426941,196.355606 
	C281.281891,191.636246 280.552582,187.193817 279.103607,183.000046 
	C277.002228,176.918076 271.911987,174.572449 263.152679,174.767578 
	C257.515259,174.893173 256.669708,179.724258 253.288208,183.374817 
	C256.858276,183.638336 259.275116,183.816727 262.342316,184.043121 
	C261.421600,188.366241 260.588531,192.277710 259.697235,196.462769 
	C257.285583,195.777496 255.533997,195.302948 253.796356,194.781860 
	C247.462509,192.882477 244.139481,195.219818 244.018234,201.810196 
	C243.926285,206.808441 243.999420,211.809723 243.999359,216.809662 
	C243.999252,225.708099 243.999329,234.606552 243.999329,244.556793 
	C247.694916,241.043320 250.540909,238.337555 253.386917,235.631805 
	C253.686005,235.900146 253.985107,236.168488 254.284195,236.436829 
	C250.683334,240.729202 254.008331,249.755951 244.299744,249.641388 
	C244.299744,252.493958 244.299744,255.078186 244.299744,257.780273 
	C262.004608,257.780273 279.407684,257.780273 296.954651,257.780273 
	C296.954651,254.876511 296.954651,252.299774 296.954651,250.240402 
	C294.706451,249.142075 291.935669,248.702835 291.306122,247.301590 
	C289.709229,243.747131 289.072235,239.761414 288.011292,235.991699 
	C288.011292,235.991699 287.974915,236.037689 288.623077,236.212799 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M513.000000,61.531342 
	C509.673706,60.333565 506.404419,58.533566 502.988861,57.076412 
	C501.861908,56.595650 500.357300,57.000084 499.025360,57.000050 
	C346.352051,56.995804 193.678741,56.991585 40.506729,56.494736 
	C39.671886,50.293636 39.858646,44.493774 38.880531,38.897514 
	C37.301899,29.865419 27.108122,23.921425 18.295607,24.683729 
	C9.206715,25.469938 4.641684,30.817272 1.000000,38.000000 
	C1.000000,25.675512 1.000000,13.351025 1.000000,1.052943 
	C171.666672,1.052943 342.333344,1.052943 513.000000,1.052943 
	C513.000000,21.091486 513.000000,41.077084 513.000000,61.531342 
z"
        />
        <path
          fill="#2E3033"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,38.468658 
	C4.641684,30.817272 9.206715,25.469938 18.295607,24.683729 
	C27.108122,23.921425 37.301899,29.865419 38.880531,38.897514 
	C39.858646,44.493774 39.671886,50.293636 40.044044,56.463379 
	C39.766220,58.346447 39.179573,59.768040 39.178497,61.190075 
	C39.152988,94.890587 39.190479,128.591156 39.330055,162.767120 
	C39.350441,171.875046 39.189079,180.507446 39.176750,189.140060 
	C39.139256,215.393692 39.159321,241.647415 39.132774,268.356873 
	C39.077721,303.030090 39.039211,337.247559 39.045723,371.465027 
	C39.045967,372.752808 39.483040,374.040497 39.890041,375.717285 
	C40.042355,406.893372 40.057259,437.680511 39.981014,468.467377 
	C39.953197,479.699066 33.031631,488.537964 20.400999,489.883484 
	C12.826169,490.690430 3.818024,483.911469 1.350629,476.112976 
	C1.000000,330.312439 1.000000,184.624878 1.000000,38.468658 
z"
        />
        <path
          fill="#F0C82A"
          opacity="1.000000"
          stroke="none"
          d="
M288.050873,235.948380 
	C289.072235,239.761414 289.709229,243.747131 291.306122,247.301590 
	C291.935669,248.702835 294.706451,249.142075 296.954651,250.240402 
	C296.954651,252.299774 296.954651,254.876511 296.954651,257.780273 
	C279.407684,257.780273 262.004608,257.780273 244.299744,257.780273 
	C244.299744,255.078186 244.299744,252.493958 244.299744,249.641388 
	C254.008331,249.755951 250.683334,240.729202 254.284195,236.436829 
	C253.985107,236.168488 253.686005,235.900146 253.386917,235.631805 
	C250.540909,238.337555 247.694916,241.043320 243.999329,244.556793 
	C243.999329,234.606552 243.999252,225.708099 243.999359,216.809662 
	C243.999420,211.809723 243.926285,206.808441 244.018234,201.810196 
	C244.139481,195.219818 247.462509,192.882477 253.796356,194.781860 
	C255.533997,195.302948 257.285583,195.777496 259.697235,196.462769 
	C260.588531,192.277710 261.421600,188.366241 262.342316,184.043121 
	C259.275116,183.816727 256.858276,183.638336 253.288208,183.374817 
	C256.669708,179.724258 257.515259,174.893173 263.152679,174.767578 
	C271.911987,174.572449 277.002228,176.918076 279.103607,183.000046 
	C280.552582,187.193817 281.281891,191.636246 282.426941,196.355606 
	C284.854736,195.588394 287.012115,194.747009 289.245087,194.235779 
	C293.302063,193.306976 296.924805,195.251694 296.990112,199.067642 
	C297.242401,213.812286 297.092560,228.563797 297.092560,243.312759 
	C296.615417,243.672577 296.138245,244.032410 295.661102,244.392227 
	C293.315094,241.665756 290.969086,238.939270 288.247314,235.930298 
	C287.706665,235.677094 287.541779,235.706406 287.376892,235.735733 
	C287.601562,235.806625 287.826202,235.877502 288.050873,235.948380 
M282.144073,217.419144 
	C282.712494,214.668869 283.468842,211.937469 283.748260,209.158142 
	C283.847107,208.175034 283.055298,206.656662 282.202057,206.099869 
	C278.269592,203.533630 274.171967,201.220520 270.585907,199.085907 
	C267.131866,201.274582 263.467102,203.454849 259.983856,205.894241 
	C258.996216,206.585922 257.923309,208.038498 257.932434,209.133392 
	C258.017456,219.321625 263.047668,226.858948 271.802460,233.885864 
	C275.398529,228.314423 278.671021,223.244278 282.144073,217.419144 
M275.061127,246.318268 
	C275.667023,251.707840 279.291931,248.305374 282.305420,249.143448 
	C280.569122,244.616806 279.106842,240.804520 277.644562,236.992249 
	C277.033325,237.053345 276.422119,237.114441 275.810883,237.175552 
	C275.559509,239.923126 275.308136,242.670685 275.061127,246.318268 
M266.160370,241.832428 
	C265.783203,240.259018 265.406006,238.685623 265.028839,237.112213 
	C264.593231,237.110840 264.157623,237.109482 263.722046,237.108109 
	C262.303162,240.838303 260.884308,244.568497 259.254822,248.852448 
	C261.941833,248.852448 263.802032,248.852448 266.160950,248.852448 
	C266.160950,246.620224 266.160950,244.673737 266.160370,241.832428 
z"
        />
        <path
          fill="#F5F4F1"
          opacity="1.000000"
          stroke="none"
          d="
M282.043793,217.796631 
	C278.671021,223.244278 275.398529,228.314423 271.802460,233.885864 
	C263.047668,226.858948 258.017456,219.321625 257.932434,209.133392 
	C257.923309,208.038498 258.996216,206.585922 259.983856,205.894241 
	C263.467102,203.454849 267.131866,201.274582 270.585907,199.085907 
	C274.171967,201.220520 278.269592,203.533630 282.202057,206.099869 
	C283.055298,206.656662 283.847107,208.175034 283.748260,209.158142 
	C283.468842,211.937469 282.712494,214.668869 282.043793,217.796631 
z"
        />
        <path
          fill="#F5F2E9"
          opacity="1.000000"
          stroke="none"
          d="
M275.058960,245.868256 
	C275.308136,242.670685 275.559509,239.923126 275.810883,237.175552 
	C276.422119,237.114441 277.033325,237.053345 277.644562,236.992249 
	C279.106842,240.804520 280.569122,244.616806 282.305420,249.143448 
	C279.291931,248.305374 275.667023,251.707840 275.058960,245.868256 
z"
        />
        <path
          fill="#F5F4EF"
          opacity="1.000000"
          stroke="none"
          d="
M266.160645,242.279831 
	C266.160950,244.673737 266.160950,246.620224 266.160950,248.852448 
	C263.802032,248.852448 261.941833,248.852448 259.254822,248.852448 
	C260.884308,244.568497 262.303162,240.838303 263.722046,237.108109 
	C264.157623,237.109482 264.593231,237.110840 265.028839,237.112213 
	C265.406006,238.685623 265.783203,240.259018 266.160645,242.279831 
z"
        />
        <path
          fill="#F5F4F4"
          opacity="1.000000"
          stroke="none"
          d="
M288.031067,235.970032 
	C287.826202,235.877502 287.601562,235.806625 287.376892,235.735733 
	C287.541779,235.706406 287.706665,235.677094 287.923218,235.842743 
	C287.974915,236.037689 288.011292,235.991699 288.031067,235.970032 
z"
        />
      </svg>
    </div>
  );
}
