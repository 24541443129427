import React from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Label, SubmitBtn, TextInput } from "../styled/auth";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { handleCompanyCode } from "../../redux/reducers/authReducer";
import { unwrapResult } from "@reduxjs/toolkit";
const Submit = styled(SubmitBtn)`
  width: auto;
`;
export default function CompanyLogin(props) {
  const { setStep, setLoading } = props;
  const { t } = useTranslation("auth");
  const schema = yup
    .object({
      companyCode: yup.string().required(t("CompanyLogin.error")),
    })
    .required();
  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      companyCode: "",
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = methods;
  const dispatch = useDispatch();
  const submitForm = (data) => {
    setLoading(true);
    dispatch(
      handleCompanyCode({
        code: data.companyCode,
        setLoading: setLoading,
        setStep: setStep,
      })
    )
      .then(unwrapResult)
      .then((data) => {
        setLoading(false);
        if (data === undefined) {
          setError("companyCode", { message: t("CompanyLogin.error_invalid") });
          setLoading(false);
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: {
          xs: "30px auto",
          sm: "25px auto",
          md: "15px auto",
          lg: "0px",
        },
        justifyContent: "center",
        alignItems: "center",
        gap: "35px",
        minWidth: {
          xs: "300px",
          sm: "500px",
          md: "500px",
          lg: "50vw",
        },
        minHeight: {
          xs: "400px",
          sm: "400px",
          md: "400px",
          lg: "100vh",
        },
        backgroundColor: "white",
        borderRadius: {
          xs: "0px",
          sm: "15px",
          md: "15px",
          lg: "15px",
        },
        padding: {
          xs: "16px",
          sm: "16px",
          md: "16px 12px",
          lg: "16px 8px",
        },
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                maxWidth: { md: "300px", lg: "unset" },
                rowGap: {
                  xs: "10px",
                  sm: "15px",
                  md: "15px",
                  lg: "20px",
                },
              }}
            >
              <Label htmlFor="companyCode">{t("CompanyLogin.label")}</Label>

              <TextInput
                id="companyCode"
                name="companyCode"
                disableUnderline
                placeholder={t("CompanyLogin.placeholder")}
                {...register("companyCode")}
              />
              {errors.companyCode && (
                <Box sx={{ color: "red" }}>{errors.companyCode.message}</Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                gap: 1,
              }}
            >
              <Submit type="submit">{t("CompanyLogin.next")}</Submit>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
