import styled from "styled-components";

const Title = styled.div`
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: #252e48;
  text-transform: capitalize;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 25px;
  }
  @media (max-width: 1024px) {
    font-size: 22px;
  }
`;

export default Title;
