import { Button, Input } from "@mui/material";
import styled from "styled-components";

const SubmitBtn = styled(Button)`
  position: relative;
  width: 130px;
  background: var(--gradient);
  height: 40px;
  color: white;
  font-size: 10pt;
  font-weight: 900;
  box-shadow: 0px 3px 6px #0000005e;
  transition: all 0.2s ease-in-out;
`;
const TextInput = styled(Input)`
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 57px;
  background: white;
  box-shadow: 0px 0px 6px #010e3f26;
  padding-inline: 20px;
  border: none;
  border-radius: 5px;
  color: black;
  @media (max-width: 768px) {
    max-width: unset;
  }
`;
const Label = styled.label.attrs((props) => props)`
  font-weight: 600;
  font-size: 14pt;
  color: ${(props) => (props.color ? props.color : "#010e3f")};
`;
const CustomBtn = styled(Button)`
  position: relative;
  width: 130px;
  background: var(--gradient);
  height: 40px;
  width: 200px;
  color: white;
  font-size: 8pt;
  font-weight: 900;
  box-shadow: 0px 3px 6px #0000005e;

  @media (min-width: 0px) and (max-width: 450px) {
    width: 300px;
  }
`;
export { Label, TextInput, SubmitBtn, CustomBtn };
