import React, { useState } from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Input = styled.input.attrs((props) => props)`
  outline: none;
  border: none;
  box-shadow: 0px 0px 6px #010e3f26;
  width: 120px;
  height: 30px;
  padding-inline: 0.5rem;
  color: #555;
  &::placeholder {
    color: rgba(1, 14, 63, 0.3);
  }
`;
export default function Searchbar() {
  const { t } = useTranslation("layout");
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchText.trim()) {
      navigate(`/shipment/${searchText}`);
      setSearchText("");
    }
  };

  return (
    <Box
      component="form"
      sx={{ display: "flex", placeItems: "center" }}
      onSubmit={handleSubmit}
    >
      <Box
        component="button"
        type="submit"
        sx={{
          display: "grid",
          boxShadow: "0px 0px 6px #010e3f26",
          placeItems: "center",
          width: "30px",
          height: "30px",
          border: "none",
          background: "var(--gradient)",
          padding: "1px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 34 34"
        >
          <path
            d="M22.846,13.962a8.556,8.556,0,0,0-2.608-6.276,8.559,8.559,0,0,0-6.276-2.609A8.555,8.555,0,0,0,7.684,7.684a8.555,8.555,0,0,0-2.608,6.277,8.561,8.561,0,0,0,2.608,6.276,8.555,8.555,0,0,0,6.277,2.608,8.552,8.552,0,0,0,6.276-2.608A8.566,8.566,0,0,0,22.846,13.962ZM33,30.462A2.573,2.573,0,0,1,30.462,33a2.357,2.357,0,0,1-1.785-.753l-6.8-6.782a13.58,13.58,0,0,1-7.913,2.459,13.714,13.714,0,0,1-5.424-1.1A13.669,13.669,0,0,1,1.1,19.386a13.912,13.912,0,0,1,0-10.848A13.669,13.669,0,0,1,8.538,1.1a13.912,13.912,0,0,1,10.848,0,13.669,13.669,0,0,1,7.437,7.437,13.7,13.7,0,0,1,1.1,5.424,13.577,13.577,0,0,1-2.459,7.913l6.8,6.8A2.429,2.429,0,0,1,33,30.462Z"
            transform="translate(4.5 3) scale(.8)"
            fill="#FFF"
          />
        </svg>
      </Box>
      <Input
        type="text"
        placeholder={t("Navbar.search_placeholder")}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        required
      />
    </Box>
  );
}
